@import 'src/utils/utils';

.footer {
  @include zero;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $Main-2;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 16px;
  padding-left: 16px;
  gap: 20px;

  @media(min-width: $md) {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 45px;
    padding-bottom: 45px;
    gap: 30px;
  }
  @media(min-width: $xxl) {
    align-items: center;
    padding-right: 55px;
    padding-left: 55px;
  }

  &__links {
    @include zero;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;

    @media(min-width: $md) {
      flex-direction: row;
      align-items: unset;
      column-gap: 30px;
      row-gap: 15px;
    }
  }

  &__link {
    @include zero;
    @include Text-16-reg;
    color: $Main-1;
    transition: color 0.3s;
    text-decoration: none;
    text-align: center;

    @media(min-width: $md) {
      text-align: unset;
    }

    &[href]:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }
  }

  &__contacts {
    @include zero;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;

    @media(min-width: $md) {
      align-items: flex-end;
    }
    @media(min-width: $xl) {
      flex-direction: row;
      align-items: unset;
      gap: 40px;
    }
  }

  &__social {
    @include zero;
    display: flex;
    column-gap: 10px;
    flex-shrink: 0;

    @media(min-width: $xxl) {
      column-gap: 20px;
    }
  }

  &__telega {
    @include zero;
    width: 40px;
    height: 40px;
    border: 1px solid $Main-5;
    border-radius: 10px;
    transition: border-color 0.3s;
    flex-shrink: 0;

    @media(min-width: $xxl) {
      width: 55px;
      height: 55px;
    }

    &:hover {
      @media(min-width: $lg) {
        border-color: $Corporate-2;
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__support {
    @include Text-14-reg;
    @include zero;
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding-top: 7px;
    padding-right: 10px;
    padding-bottom: 7px;
    padding-left: 10px;
    color: $Main-1;
    border-radius: 10px;
    border: 1px solid $Main-5;
    text-decoration: none;
    flex-shrink: 0;
    transition:
      color 0.3s,
      border-color 0.3s;

    @media(min-width: $md) {
      @include Text-16-reg;
    }
    @media(min-width: $xxl) {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 25px;
      padding-left: 25px;
    }

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
        border-color: $Corporate-2;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__corners {
    @include Text-16-reg;
    @include zero;
    display: flex;
    align-items: center;
    color: $Main-1;
    text-decoration: none;
    transition: color 0.3s;
    gap: 5px;
    flex-shrink: 0;

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    svg {
      width: 18px;
      height: 18px;
      fill: currentcolor;
    }
  }
}